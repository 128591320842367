import React, { ReactNode, useCallback, useRef, useState } from "react";
import clxs from "./immediate-assistance-form.module.css";
import Form, { FormProps } from "../../Form";
import usePhoneExts from "../../../hooks/use-phone-exts";
import useClx from "../../../hooks/use-clx";
import { useSendRequestDefaultValues } from "../../../hooks/use-send-request-default-values";
import { TextField } from "../../Form/Fields";
import Select from "../../Select";
import BUDGET_OPTIONS from "../../../core/budget-per-night";
import { MARKETING_PAGE_SCHEMA } from "./immediate-assistance-schema";
import { useSnackbar } from "../../../hooks/use-snackbar";
import useUserService from "../../../hooks/use-user-service";
import Otp from "../../Form/Fields/Otp";
import Button from "../../Button";
import Collapse from "../../Collapse";
import CALL_PRIMARY_ICON from "../../../assets/icons/call-primary.svg";
import Loader from "../../Loader";
import prepareMeta from "../../../../utils/prepare-meta";
import useUtm from "../../../hooks/use-utm";
import usePathParams from "../../../hooks/use-path-params";

interface ImmediateAssistanceFormProps
  extends Omit<FormProps, "validationSchema" | "defaultValue"> {
  titleNode?: ReactNode;
  defaultValue?: any;
  id: string;
  className?: string;
  isClearOnSubmit?: boolean;
  showInternational:boolean;
}

function ImmediateAssistanceForm(props: ImmediateAssistanceFormProps) {
  const phoneExts = usePhoneExts(),
    { id, className, showInternational } = props,
    { utm } = useUtm(),
    ccx = useClx(clxs.container, className),
    formRef = useRef<HTMLFormElement | null>(null),
    fcx = useClx(clxs.twoColumn, "full-name"),
    pecx = useClx(clxs.phoneExt, "phone-ext"),
    pcx = useClx(clxs.phoneNo, "phone-no"),
    ecx = useClx(clxs.twoColumn, "email"),
    mcx = useClx(clxs.twoColumn, "message"),
    location_slug = usePathParams(PATH, "location_slug"),
    { titleNode, defaultValue, ...rest } = props,
    dv = useSendRequestDefaultValues(defaultValue),
    [isLeadGenerated, setisLeadGenerated] = useState<boolean>(false);

  const [collapsed, setCollapsed] = useState<boolean>(false),
    showOtpField = useCallback(
      () => setCollapsed((collapsed) => !collapsed),
      [setCollapsed],
    );

  const enqueueSnackbar = useSnackbar();

  const [loader, setLoader] = useState(false);

  const { service: userService } = useUserService();

  const handleGenerateOtp = useCallback(async (value: any) => {

    if(!value) {
      return;
    }

    const { phone_ext, phone_no } = value;

    if (!phone_ext || !phone_no) {
      enqueueSnackbar("Invalid contact details.", "error");
    }

    setLoader(true);

    const { error } = await userService.generateLeadOtp(
      phone_ext, 
      phone_no,
    );

    setLoader(false);

    if (error) {
      enqueueSnackbar(error.message, "error");
      return;
    } else {
      !collapsed && showOtpField();
    }
    
  }, [userService]);

  const resendOtp = (isResendOtp: boolean) => {
    if(isResendOtp) {
      formRef.current?.submit();
    }
  }

  const handleVerifyOtp = async (value: any) => {
    
    if(!value || !value.otp) {
      enqueueSnackbar("Invalid OTP.", "error");
      return;
    }

    if(formRef && formRef.current) {
      const name = (formRef?.current[0] as HTMLInputElement)?.value;
      const phone_ext = (formRef?.current[1] as HTMLInputElement)?.value;
      const phone_no = (formRef?.current[2] as HTMLInputElement)?.value;
      const email = (formRef?.current[3] as HTMLInputElement)?.value;
      const budget = (formRef?.current[4] as HTMLSelectElement)?.value;
      const otp = value.otp;

    const { error } = await userService.verifyAndGenerateLead(
      phone_ext,
      phone_no,
      otp,
      name,
      email,
      budget,
      prepareMeta(utm),
      location_slug,
    );

    if (error) {
      enqueueSnackbar(error.message, "error");
      return;
    } else {
      showOtpField();
      enqueueSnackbar("Thank you! We will reach out to you soon!", "success");
      setisLeadGenerated(true);
      return;
    }
  }
  };

  return (
    <>
    <div className={ccx}>
    {(!isLeadGenerated) ? (
        <Form
          {...rest}
          ref={formRef}
          className={clxs.form}
          defaultValue={dv}
          validationSchema={MARKETING_PAGE_SCHEMA}
          onSubmit={handleGenerateOtp}
        >
        {titleNode}
        <TextField
          form={id}
          name="full_name"
          className={fcx}
          placeholder="Full name *"
        />
        <Select
          form={id}
          name="phone_ext"
          options={phoneExts}
          className={pecx}
          placeholder="+91 India"
        />
        <TextField
          form={id}
          name="phone_no"
          className={pcx}
          placeholder="Phone number *"
        />
        <TextField 
          form={id} 
          name="email" 
          className={ecx} 
          placeholder="Email" 
        />
        {showInternational &&
        <Select
          form={id}
          name="budget"
          options={BUDGET_OPTIONS}
          className={mcx}
          // label="Message"
          placeholder="Budget per night"
        />}

      {collapsed ? null :
        <Button
          className={clxs.submit}
          type="submit"
          form={id}
        >
          Submit
        </Button>}
        </Form>
  ) : null}
    {collapsed ? (
        <Collapse
          expanded={collapsed}
          outerClassName={clxs.collapseClassName}
          className={clxs.contentClassName}
        >
          <Form
            id={VERIFY_OTP_FORM}
            defaultValue={{otp: ""}}
            onSubmit={handleVerifyOtp}
          >
          <div 
            className={clxs.otpContainer} 
          >
            <div className={clxs.title}>OTP Verification</div>
            <Otp
              form={VERIFY_OTP_FORM}
              isImmediateAssistanceForm={true}
              name="otp"
              timeout={60}
              onGenerate={resendOtp}
            />
            <button 
              className={clxs.verify} 
              type="submit" 
              form={VERIFY_OTP_FORM}
            >
              Verify OTP & Submit
            </button>
            <Loader isLoading={loader}></Loader>
          </div>
          </Form>
        </Collapse>
      ) : null}
      {isLeadGenerated ? (
        <div className={clxs.successContainer}>
          {titleNode}
          <div className={clxs.successTitle}>
          Thank you for submitting your information. We will reach out to you soon.
          </div>
          <a
            href={"tel:+918430600600"}
            className={clxs.callUs}
          >
            <img
              src={CALL_PRIMARY_ICON}
              alt="call"
              className={clxs.callIcon}
            />
            <span className={clxs.webCall}>{"Call us +918430600600"}</span>
            <span className={clxs.respCall}>{"Call us"}</span>
          </a>
        </div>
        
      ) : null}
      </div>
    </>
  );
}

export default ImmediateAssistanceForm;

const VERIFY_OTP_FORM = "verify_otp";

const PATH = "/locations/:location_slug";